@import '~font-awesome/css/font-awesome.min.css';

.header>.container-fluid,
.header>.container-sm,
.header>.container-md,
.header>.container-lg,
.header>.container-xl,
.header>.container-xxl,
.sidebar-header {
    min-height: unset !important;
}

body {
    .button {
        cursor: pointer;
    }

    .button:active {
        opacity: 0.6;
    }

    .canvasjs-chart-credit {
        display: none;
    }

    .header.header-sticky {
        position: sticky;
        top: 0;
        z-index: 10200;
    }

    .sidebar {
        --cui-sidebar-zindex: 10350;
    }

    .modal {
        top: 50px;
        &.fade.show{
            background-color: var(--custom-modal-background);
        }
    }
    .modal-backdrop{
        background-color: unset;
    }

    .header-nav {
        .nav-link {
            &.active {
                border-bottom: solid var(--cui-primary);
            }
        }
    }

}

[data-coreui-theme=light] {
    --custom-text-color: black;
    --custom-bg-color: #00000020;
    --custom-modal-background: #080a0c73;
    --cui-primary :  #00816d;
    --cui-link-color: #00816d;
    --custom-light-color: rgba(234,243,235,255);
    --cui-link-hover-color: #00816d;
    .sidebar{
        --cui-sidebar-bg : #00816d;
        --cui-tertiary-color : white;
        --cui-body-color: white;
        --cui-tertiary-bg: var(--custom-light-color);
        --cui-sidebar-nav-group-indicator-hover-color: white;

        .nav-link:hover{
            color: white;
            background: #ffffff42;
            &.nav-group-toggle::after {
                background-color: white;
            }
        }
        .nav-link.active {
            color: white;
            background: #ffffff42;
            .nav-icon{
                color: white;
            }
        }
    }
    
    .table {
        &.table-pylab {
            border: unset;

            thead tr th {
                // background-color: #6667AB;
                background-color: var(--cui-primary);
                color: white;
                // font-weight: unset;
                // border: unset;
                // border-right: 1px solid #d6d7d8;

                div {
                    white-space: nowrap;
                }
            }

            th:last-child {
                // border-right: none;
            }

            td {
                // border: unset;
                // border-right: 1px solid #e9eaeb;
            }

            td:last-child {
                // border-right: none;
            }
        }
    }


    .pylab_tab_menu {
        .nav-tabs .nav-link {
            color: #8A8EA6;
            padding: 8px 35px !important;
            border: unset;
            border-bottom: 1px solid transparent;
        }

        .nav-tabs .nav-link.active {
            background-color: var(--cui-primary);
            color: #fff !important;
            border-color: #6667ab;
            border-radius: unset;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;

        }

        .nav-tabs .nav-link:hover,
        .nav-tabs .nav-link:focus {
            isolation: isolate;
            border: unset;
            border-bottom: 1px solid #6667ab;
            color: #6667ab;
        }
    }

    .input {
        border: solid thin #dddddd;
        border-radius: 4px;
        padding: 3px 5px;

        &.filled {
            border: solid thin var(--cui-primary);
            background-color: var(--custom-light-color);
        }

        &.empty {
            border: solid thin #dddddd;
        }

        &:focus{
            border: solid thin var(--cui-primary);
            outline: none;
        }

        
    }

    .dropdown-item {
        &:active{
            background-color: var(--cui-primary);
        }
    }

    .cus_modal{
        .modal-xl{
            --cui-modal-width : 80vw
        }
   
    }

    .triangle_red {
        width: 0;
        height: 0;
        border-left: 8px solid transparent; 
        border-right: 8px solid transparent; 
        border-top: 16px solid red; /* Bây giờ tam giác sẽ quay xuống */
    }
    .triangle_green {
        width: 0;
        height: 0;
        border-left: 7px solid transparent; 
        border-right: 7px solid transparent; 
        border-bottom: 14px solid limegreen; 
    }

    

}

[data-coreui-theme=dark] {
    --custom-text-color: white;
    --custom-bg-color: #ffffff20;
    // --cui-primary: #00816d;
    // --cui-link-color: #00816d;
    // --cui-link-hover-color: #00816d;
    --custom-modal-background: #a7d3ff69;
}

.bar-function {
    padding: 5px 20px;
    // color: white;
    background: var(--cui-primary);
    border: solid thin white;
}

.title-filter {
    background: var(--cui-primary);
    border: solid thin white;
    padding: 0px 10px;
    color: white;
    border-radius: 5px;
}

.button_filter {
    padding: 5px 20px;
    color: white;
    margin: 5px;
    background: var(--cui-primary);
    position: relative;
    font-size: 12px;
    border: solid thin white;
    border-radius: 5px;
}